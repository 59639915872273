'use strict';

angular.module('enervexSalesappApp').controller('ForgotCtrl', function($scope, Auth, Flash, _) {
	$scope.user = {};
	$scope.errors = {};

	$scope.forgot_password = function(form) {
		$scope.submitted = true;
		$scope.success = false;
		if (form.$valid) {
			Flash.clear();
			Auth.forgotPassword({
				email: $scope.user.email,
			}).then(function(data) {
				
				$scope.success = true;
				var message = '<strong>Success!</strong> Please check your email for the reset link.';
				Flash.create('success', message, 0, {}, true);
			}).catch(function(res) {
				
				$scope.errors = {};
				var message = '<strong>Error</strong> '+res.data.message;
				Flash.create('danger', message, 0, {}, true);

				// Update validity of form fields that match the mongoose errors
				_.each(err.errors, function(error, field) {
					form[field].$setValidity('mongoose', false);
					$scope.errors[field] = error.message;
				});
			});
		}
	};
});
